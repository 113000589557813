import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';

const ReturnInfo = ({ register, setValue }) => {
    const [formData, setFormData] = useState({
        startDate: null,
        endDate: null,
        regDate: null,
        startTime: null,
        endTime: null
    });

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <Card className="mb-2">
            <Card.Header as="h5">Return (Routrip)</Card.Header>
            <Card.Body className="bg-light">
                <Row className="gx-1 gy-0">
                    <Col md="6">
                        <Form.Group controlId="endDate">
                            <Form.Label>Return Date</Form.Label>

                            <DatePicker
                                selected={formData.endDate}
                                onChange={newDate => {
                                    handleChange('endDate', newDate);
                                    setValue('endDate', newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'd/m/y',
                                            name: 'endDate',
                                            ...register('endDate')
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="endTime">
                            <Form.Label>Time</Form.Label>

                            <DatePicker
                                selected={formData.endTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm"
                                onChange={newDate => {
                                    handleChange('endTime', newDate);
                                    setValue('endTime', newDate);
                                }}
                                customInput={
                                    <CustomDateInput
                                        formControlProps={{
                                            placeholder: 'H:i',
                                            name: 'endTime',
                                            ...register('endTime')
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Col>

                    <Col md="4">
                        <Form.Group controlId="venue">
                            <Form.Label>Adult</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="จำนวนคน"
                                name="venue"
                                {...register('venue')}
                            />
                            {/* <Button size="sm" variant="link" className="p-0">
                                Online Event
                            </Button> */}
                        </Form.Group>
                    </Col>
                    <Col md="4">
                        <Form.Group controlId="address">
                            <Form.Label>Child</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="จำนวนคน"
                                name="address"
                                {...register('address')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="4">
                        <Form.Group controlId="city">
                            <Form.Label>FOC</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="จำนวนคน"
                                name="city"
                                {...register('city')}
                            />
                        </Form.Group>
                    </Col>

                </Row>
            </Card.Body>
        </Card>
    );
};

ReturnInfo.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};
export default ReturnInfo;

import React from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { AuthContextProvider } from 'authHelpers/AuthContext';

const App = () => {
  return (
    <RecoilRoot>
      <Router basename={process.env.PUBLIC_URL}>
        <AuthContextProvider>
          <Layout />
        </AuthContextProvider>
      </Router>
    </RecoilRoot>
  );
};

export default App;

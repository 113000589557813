import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import AdvanceSelect from 'components/common/advance-select';
import AdvanceSelect2 from 'components/common/advance-select2';

const BookingDetails = ({ register, setValue }) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    regDate: null,
    startTime: null,
    endTime: null
  });

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <Card className="mb-2">
      <Card.Header as="h5" >Details</Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-1 gy-0">
          <Col md="3">
            <Form.Group controlId="eventTitle">
              <Form.Label>VOUCHER NO.</Form.Label>
              <Form.Control
                type="text"
                name="eventTitle"
                placeholder="VOUCHER NO."
                {...register('eventTitle')}
              />
            </Form.Group>
          </Col>
          <Col md="9">
            <Form.Group className="mb-3">
              <Form.Label>Agent</Form.Label>
              <AdvanceSelect placeholder="Search Agent" />
              {/* <Form.Select {...register(`selectType`)}>
                <option value="1">Select Agent...</option>
                <option value="2">Nation Wide Travel Center</option>
                <option value="3">Concert or Performance</option>
                <option value="4">Conference</option>
                <option value="5">Convention</option>
                <option value="6">Dinner or Gala</option>
                <option value="7">Festival or Fair</option>
              </Form.Select> */}
            </Form.Group>

          </Col>
          <Col md="3">
            <Form.Group className="mb-3">
              <Form.Label>Booking Type</Form.Label>
              <Form.Select {...register(`bookingType`)}>
                <option value="1">ONE WAY</option>
                <option value="1">ONE DAY</option>
                <option value="2">ROUND TRIP</option>
              </Form.Select>
            </Form.Group>

          </Col>
          <Col md="9">
            <Form.Group className="mb-3">
              <Form.Label>Tour Package</Form.Label>
              <AdvanceSelect placeholder="Search Tour Package" />
              {/* <Form.Select {...register(`tourPackage`)}>
                <option value="1">Select Tour Package...</option>
                <option value="2">Nation Wide Travel Center</option>
                <option value="3">Concert or Performance</option>
                <option value="4">Conference</option>
                <option value="5">Convention</option>
                <option value="6">Dinner or Gala</option>
                <option value="7">Festival or Fair</option>
              </Form.Select> */}
            </Form.Group>

          </Col>

          <Col md="4">
            <Form.Group controlId="venue">
              <Form.Label>Adult</Form.Label>
              <Form.Control
                type="text"
                placeholder="จำนวนคน"
                name="venue"
                {...register('venue')}
              />
              {/* <Button size="sm" variant="link" className="p-0">
                                Online Event
                            </Button> */}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group controlId="address">
              <Form.Label>Child</Form.Label>
              <Form.Control
                type="text"
                placeholder="จำนวนคน"
                name="address"
                {...register('address')}
              />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group controlId="city">
              <Form.Label>FOC</Form.Label>
              <Form.Control
                type="text"
                placeholder="จำนวนคน"
                name="city"
                {...register('city')}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Price Type</Form.Label>
              <Form.Select {...register(`priceType`)}>
                <option value="1">Customer Price</option>
                <option value="2">Agent Price</option>
                <option value="3">ROUND TRIP</option>
              </Form.Select>
            </Form.Group>

          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Price Categories</Form.Label>
              <Form.Select {...register(`priceCategories`)}>
                <option value="1">Thai</option>
                <option value="2">Foreign</option>
              </Form.Select>
            </Form.Group>

          </Col>

          <Col md="6">
            <Form.Group controlId="startDate">
              <Form.Label>Travel Date</Form.Label>
              <DatePicker
                selected={formData.startDate}
                onChange={newDate => {
                  handleChange('startDate', newDate);
                  setValue('startDate', newDate);
                }}
                customInput={
                  <CustomDateInput
                    formControlProps={{
                      placeholder: 'd/m/y',
                      ...register('startDate')
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="startTime">
              <Form.Label>Time</Form.Label>
              <DatePicker
                selected={formData.startTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm"
                onChange={newDate => {
                  handleChange('startTime', newDate);
                  setValue('startTime', newDate);
                }}
                customInput={
                  <CustomDateInput
                    formControlProps={{
                      placeholder: 'H:i',
                      name: 'startTime',
                      ...register('startTime')
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          {/*
          <Col md="6">
            <Form.Group controlId="registration">
              <Form.Label>Registration Deadline</Form.Label>
              <DatePicker
                selected={formData.regDate}
                onChange={newDate => {
                  handleChange('regDate', newDate);
                  setValue('regDate', newDate);
                }}
                customInput={
                  <CustomDateInput
                    formControlProps={{
                      placeholder: 'd/m/y',
                      name: 'regDate',
                      ...register('regDate')
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="timezone">
              <Form.Label>Timezone</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="timeZone"
                {...register('timeZone')}
              >
                {timezones.map(item => (
                  <option
                    value={`${item.offset}/${item.name}`}
                    key={`${item.offset}/${item.name}`}
                  >
                    {`${item.offset}/${item.name}`}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md="12">
            <div className="border-dashed-bottom"></div>
          </Col>
         <Col md="6">
            <Form.Group controlId="venue">
              <Form.Label>Venue</Form.Label>
              <Form.Control
                type="text"
                placeholder="Venue"
                name="venue"
                {...register('venue')}
              />
              <Button size="sm" variant="link" className="p-0">
                Online Event
              </Button>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                {...register('address')}
              />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                {...register('city')}
              />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                {...register('state')}
              />
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Country"
                name="country"
                {...register('country')}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name="description"
                {...register('description')}
              />
            </Form.Group>
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

BookingDetails.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};
export default BookingDetails;

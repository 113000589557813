/* eslint-disable */
import { useRecoilState } from 'recoil';

//import { history } from './history';
import { authAtom } from 'hooks/useAuthen';
import instance from './api';

const {
    REACT_APP_API_URL_STAGING, REACT_APP_API_URL_PROD, REACT_APP_ENV, REACT_APP_API_URL_DEV, REACT_APP_API_URL_LOCAL,
    REACT_APP_URL_STAGING, REACT_APP_URL_PROD, REACT_APP_URL_DEV, REACT_APP_URL_LOCAL
} = process.env;

const apiBaseURL = (REACT_APP_ENV === "local" ? REACT_APP_API_URL_LOCAL : (REACT_APP_ENV === "production" ? REACT_APP_API_URL_PROD : (REACT_APP_ENV === "staging" ? REACT_APP_API_URL_STAGING : (REACT_APP_ENV === "development" ? REACT_APP_API_URL_DEV : REACT_APP_API_URL_DEV))));
const baseURL = (REACT_APP_ENV === "local" ? REACT_APP_URL_LOCAL : (REACT_APP_ENV === "production" ? REACT_APP_URL_PROD : (REACT_APP_ENV === "staging" ? REACT_APP_URL_STAGING : (REACT_APP_ENV === "development" ? REACT_APP_URL_DEV : REACT_APP_URL_LOCAL))));
const envName = (REACT_APP_ENV === "local" ? REACT_APP_URL_LOCAL : (REACT_APP_ENV === "production" ? "" : (REACT_APP_ENV === "staging" ? " - UAT" : (REACT_APP_ENV === "development" ? " - DEV" : " - DEV"))));




export { apiBaseURL, baseURL, envName };

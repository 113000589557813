import React, { useEffect, useMemo, useState } from 'react';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import TableHeader from './TableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { settings } from 'config';
//import { useLessons } from 'hooks/useLessons';
import { useProductionPlanning } from 'hooks/useProductionPlanning';

import Pagination from "components/common/pagination";
import Loader from 'components/common/Loader';

const moduleName = "Planing";
const moduleNameTitle = "Production Planing";
const moduleNameLower = moduleName[0].toLowerCase() + moduleName.slice(1);

const pageColumns = [
  {
    accessor: 'id',
    Header: 'Id',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, name, nameLocale, email } = rowData.row.original;
      return (
        <>
          {/* <Link to="/booking/tour-booking/tour-booking-details">
            <strong>{`BO2201-000XXX`}</strong>
          </Link>{' '} */}
          <strong>{id}</strong> <br />
          {/* <a href={`#`}>{` IVXXXX-00001 `}</a><a href={`#`}>{` RCXXXX-00001 `}</a> */}
        </>
      );
    }
  }]

const buttonColumns = [
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'fs-0'
    },
    Cell: rowData => {
      const { documentStatusName } = rowData.row.original;
      return (
        <SoftBadge
          pill
          bg={classNames({
            success: documentStatusName === 'completed',
            primary: documentStatusName === 'processing',
            warning: documentStatusName === 'pending',
            secondary: documentStatusName === 'onhold'
          })}
          className="d-block"
        >
          {documentStatusName === 'completed' && 'Completed'}
          {documentStatusName === 'processing' && 'Processing'}
          {documentStatusName === 'pending' && 'Pending'}
          {documentStatusName === 'onhold' && 'On-Hold'}
          <FontAwesomeIcon
            icon={classNames({
              check: documentStatusName === 'completed',
              redo: documentStatusName === 'processing',
              stream: documentStatusName === 'pending',
              ban: documentStatusName === 'onhold'
            })}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: () => {
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href="#!">Completed</Dropdown.Item>
            <Dropdown.Item href="#!">Processing</Dropdown.Item>
            <Dropdown.Item href="#!">On Hold</Dropdown.Item>
            <Dropdown.Item href="#!">Pending</Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item href="#!" className="text-danger">
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
]

const columns = [
  {
    accessor: 'booking-no',
    Header: 'Booking No.',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, name, nameLocale, email } = rowData.row.original;
      return (
        <>
          <Link to="/booking/tour-booking/tour-booking-details">
            <strong>{`BO2201-000XXX`}</strong>
          </Link>{' '}
          <strong>{name} {nameLocale}</strong> <br />
          <a href={`#`}>{` IVXXXX-00001 `}</a><a href={`#`}>{` RCXXXX-00001 `}</a>
        </>
      );
    }
  },
  {
    accessor: 'travel-date',
    Header: 'Travel Date',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, name, email } = rowData.row.original;
      return (
        <>
          <Link to="/booking/tour-booking/tour-booking-details">
            <strong>{`20/11/2022`}</strong>
          </Link>{' '}
          <strong>{`RT`}</strong> <br />
          <a href={`#`}>{` 25/11/2022 `}</a>
          {/* <a href={`#`}>{` RCXXXX-00001 `}</a> */}
        </>
      );
    }
  },
  {
    accessor: 'date',
    Header: 'Booking Date',
    headerProps: { className: 'pe-7' }
  },
  {
    accessor: 'name',
    Header: 'Customer',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, name, email } = rowData.row.original;
      return (
        <>
          <Link to="/booking/tour-booking/tour-booking-details">
            <strong>{id}</strong>
          </Link>{' '}
          by <strong>{name}</strong> <br />
          <a href={`mailto:${email}`}>{email}</a>
        </>
      );
    }
  },

  {
    accessor: 'address',
    Header: 'Package Name',
    Cell: rowData => {
      const { address, shippingType } = rowData.row.original;
      return (
        <>
          {address}
          <p className="mb-0 text-500">{shippingType}</p>
        </>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'fs-0'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge
          pill
          bg={classNames({
            success: status === 'completed',
            primary: status === 'processing',
            warning: status === 'pending',
            secondary: status === 'onhold'
          })}
          className="d-block"
        >
          {status === 'completed' && 'Completed'}
          {status === 'processing' && 'Processing'}
          {status === 'pending' && 'Pending'}
          {status === 'onhold' && 'On-Hold'}
          <FontAwesomeIcon
            icon={classNames({
              check: status === 'completed',
              redo: status === 'processing',
              stream: status === 'pending',
              ban: status === 'onhold'
            })}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    }
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end fs-0 fw-medium py-2'
    },
    Cell: rowData => `${settings.currency}${rowData.row.original.amount}`
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: () => {
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href="#!">Completed</Dropdown.Item>
            <Dropdown.Item href="#!">Processing</Dropdown.Item>
            <Dropdown.Item href="#!">On Hold</Dropdown.Item>
            <Dropdown.Item href="#!">Pending</Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item href="#!" className="text-danger">
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const TourBookings = () => {

  const [listedColumns, setListedColumns] = useState([]);
  const [searchValue, setSearchValue] = useState('')

  const [inputValue, setInputValue] = useState('')
  const [timer, setTimer] = useState(null)


  const pageDatas = useProductionPlanning();

  useMemo(() => {
    const cols = pageDatas?.listedColumns?.map(
      ({ name, fieldName, fieldType }) => {
        return {
          accessor: fieldName,
          Header: fieldName,
          headerProps: { className: 'pe-1' },
          cellProps: {
            className: 'py-2'
          },
          Cell: rowData => {
            const { id, name, email } = rowData.row.original;
            return (
              <>
                <Link to="/booking/tour-booking/tour-booking-details">
                  <strong>{rowData.row.original[fieldName]}</strong>
                </Link>{' '}
                {/* <strong>{name}</strong> <br />
                <a href={`#`}>{fieldName}</a><a href={`#`}>{` RCXXXX-00001 `}</a> */}
              </>
            );
          }
        }
      }
    );
    setListedColumns(cols);
  }, [pageDatas?.listedColumns]);

  // const onSearch = async () => {
  //   let req =
  //   {
  //     criteria: {
  //       searchText: ''
  //     },
  //     pageIndex: pageDatas.pageIndex,
  //     pageSize: pageDatas.perPage
  //   }

  //   console.log(' on search **** ')
  //   pageDatas.onSearch(req);

  // };


  const onSearch = ({ target }) => {
    const keyword = target.value;
    //const keyword = target.value.toLowerCase();
    //console.log(keyword)
    // setSearchValue(keyword);

    let searchCriteria = {
      criteria: {
        searchText: keyword,
      },
      pageIndex: 1,
      pageSize: pageDatas.perPage,
    };

    pageDatas.onSearch(searchCriteria)

    //if (keyword.length > 3)
    //  setSearchValue(keyword);

    // const filteredResult = rawPeople.filter(
    //   person => person.name.toLowerCase().includes(keyword) || person.institution.toLowerCase().includes(keyword)
    // );
    // setPeople(keyword.length ? filteredResult : rawPeople);

  };

  // useMemo(() => {
  //   searchValue(pageDatas?.searchCriteria?.criteria?.searchText);
  // }, [pageDatas])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     //console.log('search ...', searchValue)
  //     let searchCriteria = {
  //       criteria: {
  //         searchText: searchValue,
  //       },
  //       pageIndex: 1,
  //       pageSize: pageDatas.perPage,
  //     };

  //     pageDatas.onSearch(searchCriteria)
  //   }, 500)

  //   return () => clearTimeout(timer)
  // }, [searchValue])


  // useMemo(async () => {
  //   await onSearch()
  // }, []);


  // useEffect(() => {
  //   //console.log("search data.");
  //   if (!pageDatas.loading)
  //     console.log(pageDatas?.datas)
  // }, [pageDatas?.datas])



  return (


    <AdvanceTableWrapper
      columns={[...pageColumns, ...listedColumns, ...buttonColumns]}
      data={pageDatas?.datas}
      selection
      sortable
      pagination
      perPage={25}
    >

      <Card className="mb-3">
        <Card.Header>
          <TableHeader table moduleTitle={moduleNameTitle} onSearch={onSearch} searchText={pageDatas?.searchCriteria?.criteria?.searchText || ""} />
        </Card.Header>
        <Card.Body className="p-0">
          {pageDatas?.loadingPage ? (<Loader />) : (
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          )}

          <Row noGutters className="px-1 py-3 flex-center">
            <Col xs="auto">
              {!pageDatas.loading && (
                <Pagination
                  totalRecords={pageDatas.totalRecord}
                  pageLimit={pageDatas.perPage}
                  pageNeighbours={1}
                  pageIndex={pageDatas.pageIndex}
                  onPageChanged={pageDatas.onPageChanged}
                />)}
            </Col>
          </Row>
        </Card.Body>
        {/* <Card.Footer>
          <AdvanceTablePagination table />
          <Row noGutters className="px-1 py-3 flex-center">
            <Col xs="auto">
              {!pageDatas.loading && (
                <Pagination
                  totalRecords={pageDatas.totalRecord}
                  pageLimit={pageDatas.perPage}
                  pageNeighbours={1}
                  pageIndex={pageDatas.pageIndex}
                  onPageChanged={pageDatas.onPageChanged}
                />)}
            </Col>
          </Row>
        </Card.Footer> */}
      </Card>
    </AdvanceTableWrapper>

  );
};

export default TourBookings;

import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import AdvanceSelect from 'components/common/advance-select';
import AdvanceSelect2 from 'components/common/advance-select2';

// New adds
import data from './data.json';
import { DynamicForm } from 'components/common/dynamic-form';

const Manpower = ({
  register,
  setValue,
  handleform,
  moduleName,
  control,
  formName,
  fields
}) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    regDate: null,
    startTime: null,
    endTime: null
  });

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [date, setDate] = useState(null);

  return (
    <Card className="mb-2">
      <Card.Header as="h5"> Project Objective. </Card.Header>
      <Card.Body className="bg-light">
        <Row className="mb-3 g-3">
          <Col md="12">
            <Form.Group as={Col}>
              <Form.Label>ระยะเวลา</Form.Label>
              <DatePicker
                selected={date}
                onChange={date => setDate(date)}
                dateFormat="MMMM yyyy"
                className="form-control"
                placeholderText="Select Month"
                showMonthYearPicker
              />
            </Form.Group>

            {data && (
              <DynamicForm
                handleform={handleform}
                moduleName={moduleName}
                control={control}
                refs={data}
                data={[]}
              />
            )}

          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Manpower.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};
export default Manpower;

import React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';

const Loader = props => (


  <Row className="flex-center py-5">
    <Col xs="auto">
      <Spinner animation="border" role="status" variant="primary" {...props} />
    </Col>
  </Row>
);

// Loader.propTypes = { ...Spinner.propTypes };

// Loader.defaultProps = {
//   type: 'border', // border grow
//   size: 'lg',
//   color: 'primary'
// };

export default Loader;

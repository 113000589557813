import { atom, selector } from "recoil";

const baseApi = "lessons";
const defaultPerPage = 25;
const defaultPageIndex = 1;

const datasAtom = atom({
    key: `${baseApi}datasAtom`,
    default: [],
});

const dataAtom = atom({
    key: `${baseApi}dataAtom`,
    default: [],
});


const listedColumnsAtom = atom({
    key: `${baseApi}listedColumnsAtom`,
    default: [],
});

const linksAtom = atom({
    key: `${baseApi}linksAtom`,
    default: {},
});


const searchCriteriaAtom = atom({
    key: `${baseApi}searchCriteriaAtom`,
    default: {
        criteria: {
            searchText: ""
        },
        pageIndex: defaultPageIndex,
        pageSize: defaultPerPage,
    },
});


const dataOptionAtom = atom({
    key: `${baseApi}OptionsAtom`,
    default: [],
});

const perPageAtom = atom({
    key: `${baseApi}perPageAtom`,
    default: defaultPerPage,
});

const pageIndexAtom = atom({
    key: `${baseApi}pageIndexAtom`,
    default: defaultPerPage,
});

const totalRecordAtom = atom({
    key: `${baseApi}totalRecordAtom`,
    default: 1,
});



export {
    datasAtom, dataAtom, listedColumnsAtom, linksAtom, searchCriteriaAtom,
    dataOptionAtom, perPageAtom, pageIndexAtom, totalRecordAtom
};

import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';

const Header = ({ selectedRowIds, moduleTitle, onSearch, searchText, createLink }) => {
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{moduleTitle}</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            {/* <Form.Control
              size="sm"
              type="text"
              name="eventTitle"
              placeholder="search"
            // {...register('eventTitle')}
            /> */}
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              {/* <option value="refund">Refund</option> */}
              <option value="delete">Delete</option>
              {/* <option value="archive">Archive</option> */}
            </Form.Select>
            <Button
              type="button"
              variant="syscore-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>

          </div>
        ) : (

          <div id="orders-actions" className="d-flex fs-0 mb-0 text-nowrap py-2 py-xl-0">

            <Form.Control
              size="sm"
              type="text"
              name="eventTitle"
              placeholder="search"
              className='mx-2'
              value={searchText}
              onChange={onSearch}
            // {...register('eventTitle')}
            />

            <IconButton
              variant="syscore-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              as={Link}
              to={createLink}
            >
              <span className="d-none d-sm-inline-block ms-1">Create</span>
            </IconButton>
            {/* <IconButton
              variant="syscore-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
            <IconButton
              variant="syscore-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
            </IconButton> */}


          </div>

        )}
      </Col>
    </Row>
  );
};

Header.propTypes = {
  selectedRowIds: PropTypes.object
};

export default Header;

import axios from 'axios';
import { createContext, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// const { REACT_APP_API_URL } = process.env;
import { apiBaseURL } from '_helpers';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // if (localStorage.getItem("tokens")) {
    //   let tokens = JSON.parse(localStorage.getItem("tokens"));
    //   return jwt_decode(tokens.accessToken);
    // }
    if (Cookies.get('tokens')) {
      let tokens = JSON.parse(Cookies.get('tokens'));
      return jwt_decode(tokens.accessToken);
    }
    return null;
  });

  const navigate = useNavigate();

  const login = async payload => {
    const apiResponse = await axios.post(
      `${apiBaseURL}/auth0/api/authenticate/login`,
      payload
    );

    //console.log('apiResponse >>', apiResponse)
    //localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
    Cookies.set('tokens', JSON.stringify(apiResponse.data), { expires: 7 });
    setUser(jwt_decode(apiResponse.data.accessToken));
    //setUser(jwt_decode(apiResponse.data.token));
    navigate(`/dashboard?ts=${Date.now()}`);
  };
  const logout = async () => {
    // invoke the logout API call, for our NestJS API no logout API
    Cookies.remove('tokens');
    //localStorage.removeItem("tokens");
    setUser(null);
    navigate(`/?ts=${Date.now()}`);
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

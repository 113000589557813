import React, { useState } from 'react';
import { Card, Col, Form, Row, Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import AdvanceSelect from 'components/common/advance-select';
import AdvanceSelect2 from 'components/common/advance-select2';

// New adds
import data from './data.json';
import { DynamicForm } from 'components/common/dynamic-form';
import { ArrayForm } from 'components/common/array-form';

const Sales = ({
  register,
  setValue,
  handleform,
  moduleName,
  control,
  fields
}) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    regDate: null,
    startTime: null,
    endTime: null
  });

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // WEEK
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // Month
  const [date, setDate] = useState(null);

  return (
    <Card className="mb-2">
      <Card.Header as="h5"> ยอดขายนม UHT </Card.Header>
      <Card.Body className="bg-light">
        {/* 1. UHT SIZE 225 มล. */}
        <h6 className="fs-0">
          <u> # UHT. 225 มล. </u>
        </h6>
        {/* WEEK 1 */}
        <Row className="gx-1 gy-0">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 1</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 2 */}
        <Row className="gx-1 gy-0 py-4">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 2</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 3 */}
        <Row className="gx-1 gy-0">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 3</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 4 */}
        <Row className="gx-1 gy-0 py-4">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 4</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 5 */}
        <Row className="gx-1 gy-0">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 5</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* 2. UHT SIZE 180 มล.(36)  */}
        <h6 className="fs-0 py-2">
          <u> # UHT. 180 มล.(36) </u>
        </h6>
        {/* WEEK 1 */}
        <Row className="gx-1 gy-0">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 1</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 2 */}
        <Row className="gx-1 gy-0 py-4">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 2</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 3 */}
        <Row className="gx-1 gy-0">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 3</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 4 */}
        <Row className="gx-1 gy-0 py-4">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 4</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* WEEK 5 */}
        <Row className="gx-1 gy-0">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 5</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>

        {/* 3. UHT SIZE 125 มล.(36)  */}
        <h6 className="fs-0 py-2">
          <u> # UHT. 125 มล.(36) </u>
        </h6>
        {/* WEEK 1 */}
        <Row className="gx-1 gy-0">
          <Col md="12">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>WEEK 1</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={update => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                  placeholderText="Select Week"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Month</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  dateFormat="MMMM yyyy"
                  className="form-control"
                  placeholderText="Select Month"
                  showMonthYearPicker
                />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                {data && (
                  <DynamicForm
                    handleform={handleform}
                    moduleName={moduleName}
                    control={control}
                    refs={data}
                    data={[]}
                  />
                )}
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Sales.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};
export default Sales;

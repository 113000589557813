import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderInfo from './OrderInfo';
import OrderedProducts from './OrderedProducts';
import SoftBadge from 'components/common/SoftBadge';
import { useForm } from 'react-hook-form';
import BookingDetails from './BookingDetails';
import EventTicket from './EventTicket';
import EventOtherInfo from './EventOtherInfo';
import CustomerInfo from './CustomerInfo';
import HotelInfo from './HotelInfo';
import PaymentInfo from './PaymentInfo';
import ReturnInfo from './ReturnInfo';


const TourBookingDetails = () => {
  const defaultValues = {
    timeZone: 'GMT-12:00/Etc/GMT-12',
    selectType: '1',
    selectTopic: '1'
  };
  const submittedValues = {};
  const { register, handleSubmit, setValue, control, reset } = useForm({
    defaultValues
  });

  const onSubmit = data => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = '';
    }
    const allValues = { ...submittedValues, ...defaultValues };
    reset({ ...allValues });
  };

  return (
    <>
      <PageHeader title="Booking : #BO2201-000XXX" titleTag="h5" className="mb-2">
        <p className="fs--1 mt-1">April 21, 2019, 5:33 PM</p>
        <div>
          <strong className="me-2">Status: </strong>
          <SoftBadge pill bg="success" className="fs--2">
            {' '}
            Completed
            <FontAwesomeIcon
              icon="check"
              className="ms-1"
              transform="shrink-2"
            />
          </SoftBadge>
        </div>
      </PageHeader>
      {/* <OrderInfo />
      <OrderedProducts /> */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-2">
          {/* <Col xs={12}>
            <EventHeader />
          </Col>
          <Col xs={12}>
            <EventBanner />
          </Col> */}
          <Col lg={8}>
            <BookingDetails register={register} setValue={setValue} />
            <ReturnInfo register={register} setValue={setValue} />

            <CustomerInfo register={register} setValue={setValue} />
            <HotelInfo register={register} setValue={setValue} />



            <EventTicket
              register={register}
              control={control}
              setValue={setValue}
            />



            {/*
          <EventSchedule register={register} setValue={setValue} />
          <EventUpload register={register} setValue={setValue} />
          <EventCustomField
            register={register}
            control={control}
            setValue={setValue}
          /> */}
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <Row className="gx-0 gy-1">
                <PaymentInfo register={register} control={control} />
                <EventOtherInfo register={register} control={control} />
              </Row>

            </div>
          </Col>

          <Col>
            {/* <EventFooter /> */}
          </Col>
        </Row>
      </Form>

    </>
  );
};

export default TourBookingDetails;

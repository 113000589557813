export const version = '3.5.1';
export const versionNumber = '1.0.5';
export const moduleName = "Aonang";
export const systemName = "Aonang";
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '฿',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'inverted',
  isShowIconLogo: false,
  isShowPurchase: false,
  isShowSetting: false,
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };

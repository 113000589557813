/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import {
    useSetRecoilState,
    useRecoilValue,
    useRecoilState,
    atom,
} from "recoil";
import { history, useFetchWrapper, apiBaseURL } from "_helpers";
import {
    datasAtom, dataAtom, listedColumnsAtom, linksAtom, searchCriteriaAtom,
    dataOptionAtom, perPageAtom, pageIndexAtom, totalRecordAtom
} from 'atoms/useLessonsAtoms';

import refsData from './examRefs.json';


export { useProductionPlanning };
const baseModuleApi = "s243/api";
const baseApi = "production-plannings";
const waitingTime = 500;




function useProductionPlanning() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    const [loading, setLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(true);
    const [updating, setUpdating] = useState(false);


    const [totalRecord, setTotalRecord] = useState(totalRecordAtom);
    const [perPage, setPerPage] = useRecoilState(perPageAtom);
    const [pageIndex, setPageIndex] = useRecoilState(pageIndexAtom);
    const [searchCriteria, setSearchCriteria] = useRecoilState(searchCriteriaAtom);


    const [datas, setDatas] = useRecoilState(datasAtom);
    const [data, setData] = useRecoilState(dataAtom);

    const [listedColumns, setListedColumns] = useRecoilState(listedColumnsAtom);
    const [links, setLinks] = useRecoilState(linksAtom);

    const [options, setOptions] = useRecoilState(dataOptionAtom);

    setPerPage(15)



    // useMemo(() => {
    //     //   getall();
    //     let searchCri = {
    //         criteria: {
    //             searchText: searchCriteria.criteria.searchText,
    //             courseId: searchCriteria.criteria.courseId
    //         },
    //         pageIndex: pageIndex,
    //         pageSize: perPage,
    //     };
    //     search(searchCri);
    // }, []);

    // useMemo(() => {



    //     console.log(" search all xxxxxxx")

    //     let searchCri = {
    //         criteria: {
    //             searchText: searchCriteria.criteria.searchText,
    //             courseId: searchCriteria.criteria.courseId
    //         },
    //         pageIndex: pageIndex,
    //         pageSize: perPage,
    //     };

    //     console.log(searchCri)

    //     search(searchCri);

    // }, [searchCriteria, pageIndex]);

    // useMemo(() => {
    //   //  if (isByCourse) {
    //         console.log(" search all yyyyyyy")
    //         let searchCri = {
    //             criteria: {
    //                 searchText: searchByCourseCriteria.criteria.searchText,
    //                 courseId: searchByCourseCriteria.criteria.courseId
    //             },
    //             pageIndex: pageIndex,
    //             pageSize: perPage,
    //         };
    //         console.log(searchCri)
    //         searchByCourse(searchCri);
    //   //  }

    // }, [searchByCourseCriteria, pageIndex]);

    // useMemo(() => {
    //     let request = {
    //         criteria: {
    //             searchText: searchCriteria.criteria.searchText
    //         },
    //         pageIndex: pageIndex,
    //         pageSize: perPage,
    //     };


    //     console.log(request);
    //     search(request);
    // }, [searchCriteria, pageIndex]);


    useMemo(() => {
        //   getall();
        let searchCri = {
            criteria: {
                searchText: searchCriteria.criteria.searchText,
            },
            pageIndex: pageIndex,
            pageSize: perPage,
        };
        search(searchCri);
    }, []);

    // useMemo(() => {
    //     getOptions();
    // }, [datas])

    useMemo(() => {
        let searchCri = {
            criteria: {
                searchText: searchCriteria.criteria.searchText,
            },
            pageIndex: pageIndex,
            pageSize: perPage,
        };
        search(searchCri);
    }, [searchCriteria, pageIndex]);




    return {
        options, getall, getRefs, emptyData,
        onPageChanged,
        onSearch,
        onDeleteById,
        onCreate,
        onGetById,
        onUpdateById,
        onClearData,
        totalRecord,
        perPage,
        pageIndex,
        searchCriteria,
        datas, data, listedColumns, links,
        loading,
        loadingPage,
        updating,
    };

    async function emptyData() {
        setData([]);
    }

    async function getall() {

        console.log(`9999 ==> get all ====> ${baseUrl}/${baseModuleApi}/${baseApi}`)

        return fetchWrapper.get(`${baseUrl}/${baseModuleApi}/${baseApi}`).then((response) => {

            console.log('xxxx=>', response)

            setTimeout(() => {
                setDatas(response?.data);
                setTotalRecord(response?.totalRecord);
                setLoadingPage(false);
                setLoading(false);
            }, waitingTime);
        });
    }



    async function deleteById(id) {
        //setLoadingPage(true);
        return fetchWrapper
            .delete(`${baseUrl}/${baseModuleApi}/${baseApi}/${id}`)
            .then((response) => {
                // setData(response?.data);
                // setTotalRecord(response?.totalRecord);
                // setTimeout(() => {
                //     setLoading(false);
                //     setLoadingPage(false)
                // }, waitingTime);
            });
    }

    async function getById(id) {
        setLoading(true);
        return fetchWrapper.get(`${baseUrl}/${baseModuleApi}/${baseApi}/${id}`).then((response) => {
            //console.log('hook getById data =>', response)
            setData(response);
            setTotalRecord(1);
            setTimeout(() => {
                setLoading(false);
            }, waitingTime);
            return response;
        });
    }


    async function clearData() {
        setData({});
    }


    async function updateById(id, req, onSuccess) {
        setUpdating(true);

        console.log(`${baseUrl}/${baseModuleApi}/${baseApi}/${id}`);

        return fetchWrapper
            .put(`${baseUrl}/${baseModuleApi}/${baseApi}/${id}`, req)
            .then((response) => {

                // setData(response?.data);
                // setTotalRecord(response?.totalRecord);
                setTimeout(() => {
                    setUpdating(false);
                    //setLoading(false);
                    //setLoadingPage(false)

                    if (onSuccess) onSuccess();

                }, waitingTime);
            });
    }

    async function create(req, onSuccess) {
        //setLoadingPage(true);
        setUpdating(true);
        return fetchWrapper.post(`${baseUrl}/${baseModuleApi}/${baseApi}/create`, req).then((response) => {

            //if (onSuccess) onSuccess();
            // setData(response?.data);
            // setTotalRecord(response?.totalRecord);
            setTimeout(() => {
                setUpdating(false);
            }, waitingTime);
        });
    }

    async function onUpdateById(id, req, onSuccess) {
        updateById(id, req, onSuccess);
    }

    async function onClearData() {
        clearData();
    }

    async function onCreate(req, onSuccess) {
        create(req, onSuccess);
    }

    async function onGetById(id) {
        return await getById(id);
    }

    async function onDeleteById(_id) {
        //var index = data.findIndex(({ id }) => id == _id);
        let newData = datas.filter(({ id }) => id !== _id);
        const newTotalRecord = totalRecord - 1;
        setTotalRecord(newTotalRecord);
        setDatas(newData);
        deleteById(_id);
    }

    function onPageChanged(data) {
        const { currentPage, totalPages, pageLimit } = data;
        setPageIndex(currentPage);
    }

    async function getall() {
        return fetchWrapper.get(`${baseUrl}/${baseApi}`).then((response) => {
            setTimeout(() => {
                setDatas(response?.data);
                setTotalRecord(response?.totalRecord);
                setLoadingPage(false);
                setLoading(false);
            }, waitingTime);
        });
    }

    async function onDeleteById(_id) {
        //var index = data.findIndex(({ id }) => id == _id);
        let newData = datas.filter(({ id }) => id !== _id);
        const newTotalRecord = totalRecord - 1;
        setTotalRecord(newTotalRecord);
        setDatas(newData);
        deleteById(_id);
    }

    async function getOptions() {
        if (datas && datas.length) {
            let _options = datas?.map(({ id, lessonName }) => {
                return { value: id, label: lessonName }
            })
            setOptions(_options);
        }
    }

    async function getRefs() {
        //return refsData;
        return fetchWrapper.get(`${baseUrl}/${baseModuleApi}/${baseApi}/refs`).then((response) => {
            return response;
        });
    }


    async function search(criteria) {
        setLoadingPage(true);
        return fetchWrapper
            .post(`${baseUrl}/${baseModuleApi}/${baseApi}`, criteria)
            .then((response) => {

                //console.log(' search response =>', response);

                response?.data?.map((itm) => itm.detailLinkUrl = response?.refs?.links?.find(({ linkName }) => linkName == 'listed')?.linkUrl);
                let _links =
                {
                    listedLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'listed')?.linkUrl,
                    createLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'create')?.linkUrl,
                    updateLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'update')?.linkUrl,
                    deleteLinkUrl: response?.refs?.links?.find(({ linkName }) => linkName == 'delete')?.linkUrl,
                }
                setLinks(_links);
                setDatas(response?.data);
                setTotalRecord(response?.totalRecord);
                setPerPage(response?.perPage);
                setListedColumns(response?.refs?.columns);

                setTimeout(() => {
                    setLoading(false);
                    setLoadingPage(false);
                }, waitingTime);
            });
    }


    async function deleteById(id) {
        //setLoadingPage(true);
        return fetchWrapper
            .delete(`${baseUrl}/${baseApi}/${id}`)
            .then((response) => {
                // setData(response?.data);
                // setTotalRecord(response?.totalRecord);
                // setTimeout(() => {
                //     setLoading(false);
                //     setLoadingPage(false)
                // }, waitingTime);
            });
    }


    // function onPageChanged(data) {
    //     const { currentPage, totalPages, pageLimit } = data;
    //     setPageIndex(currentPage);
    // }

    // function onSearch(criteria) {
    //     setPageIndex(1)
    //     setSearchCriteria(criteria);
    // }

    function onPageChanged(data) {
        const { currentPage, totalPages, pageLimit } = data;
        setPageIndex(currentPage);
    }

    function onSearch(criteria) {
        setPageIndex(1);
        setSearchCriteria(criteria);
    }


}

/* eslint-disable */
import {
  useSetRecoilState,
  useRecoilValue,
  useRecoilState,
  atom,
  useRecoilSnapshot
} from 'recoil';
import { useFetchWrapper, apiBaseURL } from '_helpers';
import TokenService from '_helpers/token.service';
import { useNavigate } from 'react-router-dom';

export { useAuthen, authAtom, usersAtom };

const authAtom = atom({
  key: 'auth',
  default: JSON.parse(localStorage.getItem('user'))
});

const authInfoAtom = atom({
  key: 'authInfo',
  default: JSON.parse(localStorage.getItem('authInfo'))
});

const usersAtom = atom({
  key: 'users',
  default: null
});

function useAuthen() {
  const baseUrl = `${apiBaseURL}`;
  const fetchWrapper = useFetchWrapper();

  const setAuth = useSetRecoilState(authAtom);
  const authValue = useRecoilValue(authAtom);

  const setUsers = useSetRecoilState(usersAtom);
  const usersValue = useRecoilValue(usersAtom);

  const [authInfo, setAuthInfo] = useRecoilState(authInfoAtom);

  const navigate = useNavigate();

  return {
    login,
    autnInfo,
    logout,
    register,
    getAll,
    setAuth: setAuth,
    auth: authValue,
    userInfo: usersValue,
    authInfo
  };

  function login(username, password) {
    return fetchWrapper
      .post(`${baseUrl}/auth0/api/authenticate/login`, {
        username: username,
        password: password
      })

      .then(user => {
        console.log(user);

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(user.data));
        setAuth(user);

        TokenService.setAccessToken(user?.token);
        TokenService.setRefreshToken(user?.refreshToken);

        //setTimeout(() => {
        //autnInfo();
        //}, 500);

        setTimeout(() => {
          // get return url from location state or default to home page
          //const { from } = history.location.state || { from: { pathname: `/` } };
          //history.push(from);

          navigate('/');
        }, 500);
      });
  }

  function autnInfo() {
    return fetchWrapper.get(`${baseUrl}/accounts/info`).then(user => {
      console.log('auth info', user);

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('authInfo', JSON.stringify(user));
      setAuthInfo(user);
    });
  }

  function register(reg) {
    return fetchWrapper
      .post(`${baseUrl}/accounts/register`, {
        email: reg.email,
        password: reg.password,
        confirmPassword: reg.confirmPassword,
        firstName: reg.firstName,
        lastName: reg.lastName
      })
      .then(response => {
        //history.push('/');

        //console.log(response)

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('user', JSON.stringify(user.data));
        //setAuth(user.data);

        // get return url from location state or default to home page
        const { from } = history.location.state || { from: { pathname: `/` } };
        history.push(from);

        // setTimeout(() => {
        //     history.push(from);
        // }, 500);
      });
  }

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    TokenService.setAccessToken({});
    localStorage.removeItem('user');
    setAuth(null);
    // history.push(`/`);
  }

  function getAll() {
    return fetchWrapper.get(`${apiBaseURL}/products`).then(setUsers);
  }
}

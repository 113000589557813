import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import AdvanceSelect from 'components/common/advance-select';
import AdvanceSelect2 from 'components/common/advance-select2';

// New adds
import data from './data.json';
import { DynamicForm } from 'components/common/dynamic-form';
import { ArrayForm } from 'components/common/array-form';

const Machine = ({
  register,
  setValue,
  handleform,
  moduleName,
  control,
  fields
}) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    regDate: null,
    startTime: null,
    endTime: null
  });

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [date, setDate] = useState(null);
  const [date1, setDate1] = useState(null);

  return (
    <Card className="mb-2">
      <Card.Header as="h5"> Project Details. </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-1 gy-0">
          <Col md="12">
            {data && (
              <DynamicForm
                handleform={handleform}
                moduleName={moduleName}
                control={control}
                refs={data}
                data={[]}
              />
            )}

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label>Date(วันที่ต้องการ)</Form.Label>
                <DatePicker
                  selected={date1}
                  onChange={date1 => setDate1(date1)}
                  formatWeekDay={day => day.slice(0, 3)}
                  className="form-control"
                  placeholderText="Select Date"
                />
              </Form.Group>
            </Row>

            {/* Stock product */}
            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridStock">
                <Form.Label>Stock UHT</Form.Label>
                <Form.Control type="text" value="11,500" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridStock2">
                <Form.Label>Stock Pasteurization</Form.Label>
                <Form.Control type="text" value="12,500" />
              </Form.Group>
            </Row>

            <Row className="mb-3 g-3">
              <Form.Group as={Col} controlId="formGridStock3">
                <Form.Label>Stock Yoghurt</Form.Label>
                <Form.Control type="text" value="10,000" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridStock4">
                <Form.Label>Stock Ice cream</Form.Label>
                <Form.Control type="text" value="10,000" />
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Machine.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};
export default Machine;

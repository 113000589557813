import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderInfo from './OrderInfo';
import OrderedProducts from './OrderedProducts';
import SoftBadge from 'components/common/SoftBadge';
import { useForm, useFieldArray } from 'react-hook-form';
import DetailsCard from './DetailsCard';
import EventTicket from './EventTicket';
import ItemArray from './array-item';
import EventOtherInfo from './EventOtherInfo';
import CustomerInfo from './CustomerInfo';
import HotelInfo from './HotelInfo';
import PaymentInfo from './PaymentInfo';
import ReturnInfo from './ReturnInfo';
import { DynamicForm } from 'components/common/dynamic-form';
import { ArrayForm } from 'components/common/array-form';
import DocumentStaus from 'components/common/document-status';
import { useProductionPlanning } from 'hooks/useProductionPlanning';
import Machine from './machine-info';
import Manpower from './manpower-info';
import Sales from './sales-forecast';

const moduleName = 'ProductionPlanning';
const moduleNameTitle = 'Planing Detail';
const moduleNameLower = moduleName[0].toLowerCase() + moduleName.slice(1);

const ProductionPlanningDetails = () => {
  //const { gid } = match.params;
  let { id } = useParams();
  const navigate = useNavigate();

  const [indexes, setIndexes] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  const { register, handleSubmit, setValue, control, reset } = useForm({
    defaultValues: {
      //test: [{ firstName: "Bill", lastName: "Luo" }]
      test: []
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'test'
    }
  );

  const detailData = useProductionPlanning();

  // --- dynamic form init
  const [refs, setRefs] = React.useState();
  const handleform = {
    register: register,
    setValue: setValue
  };

  let data = [];
  let fieldRefs = [];

  useMemo(async () => {
    if (!id) {
      fieldRefs = await detailData.getRefs();
      setRefs(fieldRefs);
      console.log('fieldRefs', fieldRefs);
    }
  }, []);

  useMemo(async () => {
    // initial fields and defaul data when edit
    await detailData.emptyData();
    if (id) {
      data = await detailData.onGetById(id);
      fieldRefs = await detailData.getRefs();
      setRefs(fieldRefs);
      // if (data) Object.keys(data).map(function (key) { setValue(key, data[key]); });

      addForm();
      console.log('datial data =>', data);
    }
  }, [id]);

  const defaultValues = {
    timeZone: 'GMT-12:00/Etc/GMT-12',
    selectType: '1',
    selectTopic: '1'
  };
  const submittedValues = {};

  const onSubmit1 = data => {
    console.log('submit data-->', data);
    // ------- Get all object keys form data and set empty values to reset ------------
    // const keys = Object.keys(data);
    // for (const key of keys) {
    //   submittedValues[key] = '';
    // }
    // const allValues = { ...submittedValues, ...defaultValues };
    // reset({ ...allValues });
  };

  const addForm = () => {
    console.log('add form');
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const onSubmit = data => {
    // if (id != null) {
    //   detailData.onUpdateById(id, data);
    // } else {
    //   detailData.onCreate(data);
    // }
    console.log(data);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title="โครงการ #PLN2201-000XXX"
          titleTag="h5"
          className="mb-2"
        >
          <p className="fs--1 mt-1">April 21, 2019, 5:33 PM</p>
          <div>
            <strong className="me-2">Status: </strong>
            <SoftBadge pill bg="success" className="fs--2">
              {' '}
              Completed
              <FontAwesomeIcon
                icon="check"
                className="ms-1"
                transform="shrink-2"
              />
            </SoftBadge>
          </div>

          {/*
          <Spinner size="sm"
            style={{ width: '1rem', height: '1rem' }}
            className="mr-2 mt-0"
            color="primary" /> */}
          <div
            id="orders-actions"
            className="d-flex fs-0 mb-0 mt-2 text-nowrap py-2 px-0 py-xl-0"
          >
            <IconButton
              variant="syscore-default"
              size="sm"
              icon="chevron-left"
              transform="shrink-3"
              className="mx-2"
              onClick={() => navigate(detailData?.links?.listedLinkUrl)}
            >
              <span className="d-none d-sm-inline-block ms-1">Back</span>
            </IconButton>
            <IconButton
              type="submit"
              variant="syscore-default"
              size="sm"
              icon={detailData?.updating ? '' : 'check'}
              transform="shrink-3"
            >
              {detailData?.updating && (
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{ width: '1rem', height: '1rem' }}
                  className="mr-2 mt-0"
                />
              )}
              <span className="d-none d-sm-inline-block ms-1">Save</span>
            </IconButton>
          </div>
        </PageHeader>
        {/* <OrderInfo />
        <OrderedProducts /> */}

        <Row className="g-2">
          {/* <Col xs={12}>
            <EventHeader />
          </Col>
          <Col xs={12}>
            <EventBanner />
          </Col> */}
          <Col lg={8}>
            {/* <DetailsCard register={register} setValue={setValue} /> */}

            {/* Detail */}
            {/* <Card className="mb-2">
              <Card.Header as="h5">Details</Card.Header>
              <Card.Body className="bg-light">
                <Row className="gx-1 gy-0">
                  <Col md="12">
                    {refs && (
                      <DynamicForm
                        handleform={handleform}
                        moduleName={moduleName}
                        control={control}
                        refs={refs}
                        data={detailData?.data}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card> */}

            {/* <Card className="mb-2"></Card>

            {refs?.forms &&
              refs?.forms.map(f => (
                <>
                  <Card className="mb-2">
                    <Card.Body className="bg-light"></Card.Body>
                    <Card.Header as="h5">
                      {f.formTitle}{' '}
                      <IconButton
                        onClick={addForm}
                        variant="syscore-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                      >
                        Add Item
                      </IconButton>
                    </Card.Header>
                    {refs &&
                      indexes.map(index => (
                        <>
                          <Card.Body className="bg-light">
                            <h6 className="fs-0">
                              <u>Item #{index + 1} </u>
                            </h6>
                            <Row className="gx-0 gy-0">
                              <Col md="12">
                                <ArrayForm
                                  idx={index}
                                  handleform={handleform}
                                  moduleName={moduleName}
                                  control={control}
                                  formName={f.formName}
                                  fields={f.fields}
                                  data={[]}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </>
                      ))}
                  </Card>
                </>
              ))} */}

            <Manpower
              register={register}
              setValue={setValue}
              handleform={handleform}
              moduleName={moduleName}
              control={control}
            />

            <Machine
              register={register}
              setValue={setValue}
              handleform={handleform}
              moduleName={moduleName}
              control={control}
            />

            <Sales
              register={register}
              setValue={setValue}
              handleform={handleform}
              moduleName={moduleName}
              control={control}
            />

            {/* <EventTicket
              register={register}
              setValue={setValue}
            /> */}
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <Row className="gx-0 gy-1">
                <DocumentStaus register={register} control={control} />
                {/* <PaymentInfo register={register} control={control} />
                <EventOtherInfo register={register} control={control} /> */}
              </Row>
            </div>
          </Col>

          <Col>{/* <EventFooter /> */}</Col>
        </Row>
      </Form>
    </>
  );
};

export default ProductionPlanningDetails;

import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';

const HotelInfo = ({ register, setValue }) => {
    const [formData, setFormData] = useState({
        startDate: null,
        endDate: null,
        regDate: null,
        startTime: null,
        endTime: null
    });

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <Card className="mb-2">
            <Card.Header as="h5">Hotel Info.</Card.Header>
            <Card.Body className="bg-light">
                <Row className="gx-1 gy-0">
                    <Col md="8">
                        <Form.Group controlId="venue">
                            <Form.Label>Hotel Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Contact Person<"
                                name="venue"
                                {...register('venue')}
                            />
                            {/* <Button size="sm" variant="link" className="p-0">
                                Online Event
                            </Button> */}
                        </Form.Group>
                    </Col>
                    <Col md="4">
                        <Form.Group controlId="address">
                            <Form.Label>Room No.</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Telephone"
                                name="address"
                                {...register('address')}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="12">
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                name="description"
                                {...register('description')}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

HotelInfo.propTypes = {
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};
export default HotelInfo;

import React from 'react';
import { Form, Label, FormGroup, FormText, FormFeedback, Row, Col } from 'react-bootstrap';

//import FormGroupInput from "components/common/FormGroupInput";
//import FormInput from "components/common/FormInput";


//for a select input type
const renderOptions = (options) => {
    return options.map((child) => {
        return (
            <option key={child.guiValue} value={child.guiValue}>{child.itemLabel}</option>
        );
    });
};


export function DynamicForm({ template, data, handleform, moduleName, control, refs }) {
    const moduleNameLower = moduleName[0].toLowerCase() + moduleName.slice(1);
    let { register, errors, setValue } = handleform;
    const renderFields = (fields) => {
        return fields.map(field => {
            let { fieldType, fieldName, name, required, value, validation, optionList } = field;
            switch (fieldType) {
                case 'String':
                    return (
                        <Col lg={12}>

                            <Form.Group controlId={fieldName}>
                                <Form.Label>{name}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={fieldName}
                                    {...register(fieldName)}
                                    id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                />
                            </Form.Group>

                            {/* <Form.Group controlId={fieldName}>
                                <Form.Label>{name}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={fieldName}
                                    {...register(fieldName)}
                                    id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                />

                            </Form.Group> */}

                            {/* <FormInput
                                {...register(fieldName)}

                                control={control} as={FormGroupInput} id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]} /> */}

                        </Col>
                    );
                case 'Double':
                    return (
                        <Col lg={12}>

                            <Form.Group controlId={fieldName}>
                                <Form.Label>{name}</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder={fieldName}
                                    {...register(fieldName)}
                                    id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                />



                            </Form.Group>

                            {/* <Form.Group controlId={fieldName}>
                                    <Form.Label>{name}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={fieldName}
                                        {...register(fieldName)}
                                        id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                    />

                                </Form.Group> */}

                            {/* <FormInput
                                    {...register(fieldName)}

                                    control={control} as={FormGroupInput} id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]} /> */}

                        </Col>
                    );

                case 'select':
                    let { options } = field;
                    return (
                        <FormGroup key={name}>
                            {/* <Label htmlFor={name}>{title}<span style={required ? { color: 'red' } : {}}> *</span></Label> */}
                            <Form.Label>{title}<span style={required ? { color: 'red' } : {}}> *</span></Form.Label>
                            {/* <Input
                                type='select'
                                name={name}
                                {...register(name)}
                                onChange={e => setValue(name, e.target.value)}

                            //innerRef={register}
                            >

                                {renderOptions(options)}
                            </Input> */}

                            <Form.Select {...register(name)}
                                {...register(name)}
                                onChange={e => setValue(name, e.target.value)}
                            >
                                {renderOptions(options)}
                            </Form.Select>
                        </FormGroup>
                    );

                default:
                    return (<>
                        {optionList &&
                            <FormGroup key={name}>
                                {/* <Label htmlFor={name}>{title}<span style={required ? { color: 'red' } : {}}> *</span></Label> */}
                                <Form.Label>{fieldName}<span style={required ? { color: 'red' } : {}}> *</span></Form.Label>


                                <Form.Select {...register(`${fieldName}`)}
                                    {...register(`${fieldName}`)}
                                    onChange={e => setValue(`${fieldName}`, e.target.value)}
                                >
                                    {renderOptions(optionList)}
                                </Form.Select>
                            </FormGroup>}
                    </>
                    );
            }
        });

    };
    let { title, fields } = refs;
    return (
        <>
            {/* <h4>{title}</h4> */}
            <Row>
                <Col>
                    {fields && renderFields(fields)}
                </Col>
            </Row>
        </>

    );

}
